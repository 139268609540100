//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Update from '../components/update.vue'
import moment from "moment";
import {Loading, MessageBox} from "element-ui";
import SvgIcon from "../components/SvgIcon.vue";
import { isContinueRenderPage } from "../utils/check.js";
import codeImg from '@/static/picture/code.png';

export default {
  components: { Update, SvgIcon },
  data() {
    return {
      noScroll: false,
      isShow: false,
      noticeList: [],
      noticeTotal: "", // 总通知数量
      inMail: "", // 未读站内信数量
      exportData: {},
      timer: null,
      crrActive: 0,
      isQuery: !(
        this.$route.path.includes("queryReport") && this.$route.query.query == 1
      )
        ? false
        : true // 商标查询分享页
    };
  },
  computed: {
    menuDataRes(){
      const userInfo = this.$store.state.user;
      /** 角色配置菜单
       * 1： 商标查询   2：商品/服务分类  3：商标对比   4：商标分析  5：商标公告
       * 6：裁定文书    8：商标监控   10：图形查询  11：业务线索    12：品牌风险
       * 13：专项查询   14：AI近似分析
       * */
      /*
      * uc_vip_level: 1 有限试用  2专业版  11员工 21免费试用  31公司客户
      * uc_memberType: 1代理人  2申请人  3 超凡内部员工    4其他
      * */
      let data = [];
      if(userInfo){
        if(userInfo.uc_memberType === 1 || userInfo.uc_memberType === 3) { // 代理人
          data = this.tools.menuData.filter( item => item.index !== '12');
        } else if(userInfo.uc_memberType === 2 || userInfo.uc_memberType === 4){
          data = this.tools.menuData.filter( item => item.index !== '11');
        } else {
          data = this.tools.menuData;
        }
      }else{
        data = this.tools.menuData;
      }
      /*if(userInfo.uc_vip_level == 1){  // 个人版  [1,2,3,4,5,6]
        let hasMenus = ['1','2','3','5','6']
        data = this.tools.menuData.filter(item => hasMenus.indexOf(item.index) > -1)
      } else if(userInfo && userInfo.uc_vip_level === 21) { // 免费试用
        data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '8');
      } else { // 其他版本 -- 菜单都显示（只限制次数）
        data = this.tools.menuData.filter( item => item.index !== '9');
        if(userInfo && userInfo.uc_memberType === 1) { // 代理人
          data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '12');
        }
        if(userInfo && userInfo.uc_memberType === 2) {// 申请人
          data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '11');
        }
        if(userInfo && userInfo.uc_memberType === 4) { // 其他
          data = this.tools.menuData.filter( item => item.index !== '11');
        }
      }*/
      return data
    },
    activeMenu(){
      return this.$route.path
    }
  },
  async mounted() {
    const loadingInstance = Loading.service({
      text: "加载中..."
    });
    try {
      await isContinueRenderPage.bind(this)();
    } catch (e) {
      loadingInstance.close();
      console.error("isContinueRenderPage执行失败，跳转到登录页");
      this.$router.push("/login");
      return;
    }

    loadingInstance.close();
    this.isShow = true;

    document.addEventListener("keydown", this.arrowUpDown);
    let _router = this.$route;
    if (!(_router.path.includes("queryReport") && _router.query.query == 1)) {
      // 分享出去的页面不调用该接口
      this.listInmail();
      this.noticeInfo();
      let _this = this;
      window.sendEventCall = (num, type) => {
        _this.noticeInfo();
        _this.exportData.total = "";
        type == "notice" && (_this.inMail = 0);
      };
      this.timer = setInterval(() => {
        _this.renewNotice(); // 续费通知
        setTimeout(_this.noticeInfo, 0);
      //}, 1000);
      }, 1000 * 5 * 60);
    }
    if (process.client && this.$store.state.user) {
      var st = document.createElement("style");
      st.innerHTML = this.$store.state.user.uc_css + "{display: none;}";
      document.body.appendChild(st);
    }
    console.log(this.$route.path)


    this.noScroll = this.$route.path === '/analysis/newBj'

    if(this.tools.menuData[9] && this.tools.menuData[9].name =='我的线索' && this.$store.state.user.uc_memberType !=3){
      this.tools.menuData.splice(9,1)
    }
  },
  methods: {
    jumpPaid(){
      this.$router.push({
        path:"/user/VersionIntroduction",
      })
    },
    goRoute(vc){
      if(vc.index === '/trademarkSearch'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInto", true)
      }
      if(vc.index === '/trademarkSearchInternational'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInterInto", true)
      }
      if(vc.index === this.$route.path){
        location.reload()
      } else {
        this.$router.push(vc.index)
      }

    },
    arrowUpDown(e) {
      if (e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA") {
        return;
      }
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        e.preventDefault(); //阻止默认事件
        let dom = document.getElementById("RightPlan");
        let top = dom.scrollTop;

        if (e.code === "ArrowUp") {
          let num = 4;
          const timeTop = setInterval(() => {
            dom.scrollTop = top -= 10;
            num--;
            if (num <= 0) {
              clearInterval(timeTop);
            }
          }, 10);
        } else if (e.code === "ArrowDown") {
          let num = 4;
          const timeTop = setInterval(() => {
            dom.scrollTop = top += 10;
            num--;
            if (num <= 0) {
              clearInterval(timeTop);
            }
          }, 10);
        }
      }
    },
    /*pageClick(url){
      if(url === '/trademarkSearch'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInto", true)
      }
      if(url === this.$route.path){
        location.reload()
      }
    },*/
    SHMenu() {
      let menu = !this.$store.state.isHideMenu;
      this.$store.commit("setMenu", menu);
      let nodeList = document.querySelectorAll(".j-scroll-left");
      let fixedlen = document.querySelectorAll(".fixed-head").length;
      if (nodeList.length) {
        nodeList[0].style.paddingLeft = !fixedlen || menu ? "0" : "166px";
        if (nodeList.length >= 2) {
          nodeList[1].style.paddingLeft = !fixedlen || menu ? "20px" : "186px";
        }
      }

      let jsc = document.querySelectorAll(".j-scroll-contrast");
      let fixedc = document.querySelectorAll(".fixed-head-contrast").length;
      if (jsc.length) {
        jsc.forEach(item => {
          item.style.paddingLeft = !fixedc ? "0" : menu ? "95px" : "225px";
        });
      }
    },
    listInmail() {
      this.loading = true;
      this.$axios
        .post("/api/Inmail/getList", { num: 3, sort: "desc" })
        .then(res => {
          let _data = res.data.data;
          this.noticeList = _data.rows;
          this.noticeTotal = _data.total;
        });
    },
    noticeInfo() {
      this.$axios
        .get("/api/Member/getNoticeNumber", {
          params: { id: Math.random() }
        })
        .then(res => {
          let _data = res.data.data;
          if (_data) {
            this.exportData = _data.export;
            this.inMail = _data.inMail;
            if (this.exportData) {
              if (this.exportData.successReport) {
                if (document.querySelector(".tipsSuccess")) {
                  console.log(`存在`);
                  return;
                }
                this.$notify({
                  title: "成功",
                  position: "bottom-left",
                  message: "您有新的导出任务已完成",
                  type: "success",
                  customClass: "tipsSuccess",
                  offset: 100,
                  duration: 10000,
                  onClose: () => {
                    this.reportClose(1);
                  }
                });
              } else if (this.exportData.failReport) {
                this.$notify.error({
                  title: "错误",
                  position: "bottom-left",
                  offset: 100,
                  message: "您有导出任务失败了",
                  duration: 10000,
                  onClose: () => {
                    this.reportClose(2);
                  }
                });
              }
            }
          }
        });
    },
    reportClose(num) {
      this.$axios
        .post("/api/member/closeExportReport/", { status: num })
        .then(res => {
          console.log(res);
          this.noticeInfo();
        });
    },
    openDetails(Id) {
      // window.open("/user/noticeDetails?id=" + Id);
      this.noticeInfo();
    },

    // 续费通知
    renewNotice(){
      const userInfo = this.$store.state.user;
      if(userInfo && userInfo.uc_vip_level == 11) {  // 个人版  [1,2,3,5,6]
        return;
      }
      // moment(this.$store.state.user.uc_vip_exp) 结束时间
      let fortyDaysAgo = moment(this.$store.state.user.uc_vip_exp).subtract(39, 'days').format('YYYY-MM-DD'); // 到期前40天
      let thirtyDaysAgo = moment(this.$store.state.user.uc_vip_exp).subtract(29, 'days').format('YYYY-MM-DD'); // 到期前30天
      let tenDaysAgo = moment(this.$store.state.user.uc_vip_exp).subtract(9, 'days').format('YYYY-MM-DD'); // 到期前10天
      let day = moment().startOf("day").format('YYYY-MM-DD'); //今天的时间

      let dueTimeTip = this.$cookies.get("dueTimeTip");
      if(day === fortyDaysAgo || day === thirtyDaysAgo || day === tenDaysAgo){
        if(dueTimeTip) return
        this.$axios.get("/api/Inmail/renewNotice").then(res => {
          this.$cookies.set("dueTimeTip", true);
          if(res.data.data.length === 0) return
          MessageBox.alert(`<p>${res.data.data.content}</p><div class="codeImgTip"><img src="${codeImg}"/></div>`, '提示', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '了解更多',
            center: true,
            type: 'warning',
            customClass: 'commonTip'
          }).then(()=> {
            this.$router.push({
              path:"/user/VersionIntroduction",
            })
          });
          /*MessageBox.confirm(res.data.data.content, '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          })*/
        })
      } else {
        this.$cookies.set("dueTimeTip", false);
      }
    }

  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
