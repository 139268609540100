import { Message, MessageBox } from "element-ui";
import Qs from "qs";
import { throttle } from "lodash";
import axios from "axios";
import codeImg from '../static/picture/code.png';


let getUserInfo = async function (uc_token){
  // 为了避免副作用，禁止使用$axios来发送请求
  const res = await axios.post(
    "/api/auth/getUserInfo",
    {},
    {
      headers: {
        Authorization: `Bearer ${uc_token}`
      }
    }
  );
  const countdown = res.data.data.uc_countdown;
  // currentTime = Math.floor(new Date().getTime()/1000)
  console.log(`token还剩 ${countdown} 秒过期`);
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    reflushToken();
  },1000 * (countdown - 10))
}
/**
 * 定时刷新Token
 *  */
function reflushToken() {
  console.log("开始重置token");
  // 为了避免副作用，禁止使用$axios来发送请求
  axios
    .post(
      "/api/auth/resetToken/",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("uc_token")}`
        }
      }
    )
    .then(res => {
      const newUcToken = res.data.data.uc_token;
      if (newUcToken) {
        console.log("重置 uc_token 成功", newUcToken);
        localStorage.setItem("uc_token", newUcToken);
        getUserInfo(newUcToken);
      }
    });
}

/**
 * 检查有无必要刷新token
 */
let timeout = null;
const checkTokenIsExpire = throttle(
  async () => {
    if (typeof localStorage === "undefined") {
      return;
    }
    const uc_token = localStorage.getItem("uc_token");
    // 如果不存在uc_token，则不查询有效期
    if (!uc_token) {
      return;
    }

    // 为了避免副作用，禁止使用$axios来发送请求
    getUserInfo(uc_token);
    // const res = await axios.post(
    //   "/api/auth/getUserInfo",
    //   {},
    //   {
    //     headers: {
    //       Authorization: `Bearer ${uc_token}`
    //     }
    //   }
    // );
    // const countdown = res.data.data.uc_countdown;
    // // currentTime = Math.floor(new Date().getTime()/1000)
    // console.log(`token还剩 ${countdown} 秒过期`);
    // clearTimeout(timeout);
    // timeout = setTimeout(() => {
    //   reflushToken();
    // },1000 * (countdown - 10))
    // // 如果过期时间小于24小时，则刷新token
    // // 后端7
    // // if (countdown <= 60 * 60 * 24) {
    // //   // console.log(123456)
    // //   // reflushToken();
    // //   clearTimeout(timeout);
    // //   timeout = setTimeout(() => {
    // //     reflushToken();
    // //   },1000 * (countdown - 10))
    // // }
  },
  // 24小时检查一次
  24 * 60 * 60
);


/**
 * 当页面发生点击，则认为网页正在被使用，定时检查token是否过期
 */
if (typeof document !== "undefined") {
  document.addEventListener("click", function () {
    checkTokenIsExpire();
  });
}

export default params => {
  const { redirect, $axios, error, $cookies } = params;
  $axios.defaults.timeout = 300000;
  $axios.setHeader(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );
  $axios.onRequest(config => {
    let uc_token =
      typeof localStorage === "undefined"
        ? ""
        : localStorage.getItem("uc_token");

    if (!uc_token) {
      try {
        uc_token = $cookies.get("uc_token");
      } catch {
        console.log("无法从header中获取uc_token");
      }
    }

    if (uc_token) {
      config.headers.common["Authorization"] = "Bearer " + uc_token;
    }
    let data = {};
    if (config.method.toUpperCase() === "GET") {
      data = config.params || {}
      config.params = data
    } else {
      // POST 处理参数 data
      data = config.data || {};
      data = Qs.stringify(data);
      config.data = data;
    }
  });

  $axios.onResponse(res => {
   /* console.log(111)
    console.log(res)*/
    if (!process.client) {
      console.log(res.config.url, res.status);
    }

    /**
     * 如果调用了登录或者getUserInfo，则存储ucToken到本地
     */
    if (
      res.config.url.startsWith("/api/auth/getUserInfo") ||
      res.config.url.startsWith("/api/Auth/login")
    ) {
      if (typeof localStorage !== "undefined" && res.data.data.uc_token) {
        console.log(res.data.data.uc_token)
        localStorage.setItem("uc_token", res.data.data.uc_token);
      }
    }

    if(res.data.code === -89){
      MessageBox.confirm('您的账号因访问频率异常，超过系统安全警戒值，现已被限制使用，之后每天仍可少量访问，如有异议，请使用系统左下角的 在线反馈 反应', '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      })

    }

    if(res.data.code === -9100){
      let mag = res.data.msg ? res.data.msg : '账号已过期，请联系售前人员'
      MessageBox.alert(`<p>${mag}</p><div class="codeImgTip"><img src="${codeImg}"/></div>`, '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '了解更多',
        center: true,
        type: 'warning',
        customClass: 'commonTip'
      }).then(()=> {
        redirect("/user/VersionIntroduction");
      });
    }
  /*  MessageBox.alert(`<p>46465</p><div class="codeImgTip"><img src="${codeImg}"/></div>`, '提示', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '查看详情',
      center: true,
      type: 'warning',
      customClass: 'commonTip'
    }).then(()=> {
      redirect("/user/VersionIntroduction");
    });*/


    if(res.data.code === -81){
      if(res.data.msg){
        MessageBox.confirm(res.data.msg, '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        }).then(()=> {})
      }
    }

    // 首页传isGoLogin:true  访问用户信息接口 过期也不跳转登录页
    if (res.config.data != "isGoLogin=true" && (res.data.code == -9999 || res.data.code == -9998)) {
      return redirect("/login");
    }
  });

  $axios.onError(err => {
    console.log("onError", err);
    let code = parseInt(err.response && err.response.status);
    //白名单内接口报错不做处理
    let wlist = ['/api/Inmail/getList', '/api/Member/getNoticeNumber','/api/auth/getUserInfo', '/version.json'];
    let configUrl = err.config && err.config.url;
    for (let i = 0, len = wlist.length; i < len; i++) {
      if(wlist[i]==configUrl){
        return ;
      }
    }
    if (code == 401) {//ip封锁
      return redirect("/ipBlockade?ip=" + err.response.data.data.ip);
    } else if (err == "Error: Network Error") {
      Message.error("网络异常，请稍后再试~");
    } else {
      error({
        statusCode: code,
        url: err.config && err.config.url
      });
    }
  });
};
