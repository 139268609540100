import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  BackTop: () => import('../../components/BackTop.vue' /* webpackChunkName: "components/back-top" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  PopoverDate: () => import('../../components/PopoverDate.vue' /* webpackChunkName: "components/popover-date" */).then(c => wrapFunctional(c.default || c)),
  PopoverList: () => import('../../components/PopoverList.vue' /* webpackChunkName: "components/popover-list" */).then(c => wrapFunctional(c.default || c)),
  SvgIcon: () => import('../../components/SvgIcon.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c)),
  SvgPop: () => import('../../components/SvgPop.vue' /* webpackChunkName: "components/svg-pop" */).then(c => wrapFunctional(c.default || c)),
  TopTip: () => import('../../components/TopTip.vue' /* webpackChunkName: "components/top-tip" */).then(c => wrapFunctional(c.default || c)),
  BatchSearchList: () => import('../../components/batchSearchList.vue' /* webpackChunkName: "components/batch-search-list" */).then(c => wrapFunctional(c.default || c)),
  CommonElForm: () => import('../../components/commonElForm.vue' /* webpackChunkName: "components/common-el-form" */).then(c => wrapFunctional(c.default || c)),
  CommonElLayer: () => import('../../components/commonElLayer.vue' /* webpackChunkName: "components/common-el-layer" */).then(c => wrapFunctional(c.default || c)),
  CommonElReport: () => import('../../components/commonElReport.vue' /* webpackChunkName: "components/common-el-report" */).then(c => wrapFunctional(c.default || c)),
  CommonElTable: () => import('../../components/commonElTable.vue' /* webpackChunkName: "components/common-el-table" */).then(c => wrapFunctional(c.default || c)),
  CommonImgTable: () => import('../../components/commonImgTable.vue' /* webpackChunkName: "components/common-img-table" */).then(c => wrapFunctional(c.default || c)),
  CommonTips: () => import('../../components/commonTips.vue' /* webpackChunkName: "components/common-tips" */).then(c => wrapFunctional(c.default || c)),
  ContrastTable: () => import('../../components/contrastTable.vue' /* webpackChunkName: "components/contrast-table" */).then(c => wrapFunctional(c.default || c)),
  ContrastUpload: () => import('../../components/contrastUpload.vue' /* webpackChunkName: "components/contrast-upload" */).then(c => wrapFunctional(c.default || c)),
  DetailsStep: () => import('../../components/detailsStep.vue' /* webpackChunkName: "components/details-step" */).then(c => wrapFunctional(c.default || c)),
  ExportTip: () => import('../../components/exportTip.vue' /* webpackChunkName: "components/export-tip" */).then(c => wrapFunctional(c.default || c)),
  Feedback: () => import('../../components/feedback.vue' /* webpackChunkName: "components/feedback" */).then(c => wrapFunctional(c.default || c)),
  NoData: () => import('../../components/noData.vue' /* webpackChunkName: "components/no-data" */).then(c => wrapFunctional(c.default || c)),
  NumRun: () => import('../../components/numRun.vue' /* webpackChunkName: "components/num-run" */).then(c => wrapFunctional(c.default || c)),
  PopoverBox: () => import('../../components/popoverBox.vue' /* webpackChunkName: "components/popover-box" */).then(c => wrapFunctional(c.default || c)),
  PopoverClass: () => import('../../components/popoverClass.vue' /* webpackChunkName: "components/popover-class" */).then(c => wrapFunctional(c.default || c)),
  PopoverRadio: () => import('../../components/popoverRadio.vue' /* webpackChunkName: "components/popover-radio" */).then(c => wrapFunctional(c.default || c)),
  SetPassword: () => import('../../components/setPassword.vue' /* webpackChunkName: "components/set-password" */).then(c => wrapFunctional(c.default || c)),
  TableClass: () => import('../../components/tableClass.vue' /* webpackChunkName: "components/table-class" */).then(c => wrapFunctional(c.default || c)),
  TmList: () => import('../../components/tmList.vue' /* webpackChunkName: "components/tm-list" */).then(c => wrapFunctional(c.default || c)),
  Update: () => import('../../components/update.vue' /* webpackChunkName: "components/update" */).then(c => wrapFunctional(c.default || c)),
  UserList: () => import('../../components/userList.vue' /* webpackChunkName: "components/user-list" */).then(c => wrapFunctional(c.default || c)),
  UserTable: () => import('../../components/userTable.vue' /* webpackChunkName: "components/user-table" */).then(c => wrapFunctional(c.default || c)),
  AiContent: () => import('../../components/ai/aiContent.vue' /* webpackChunkName: "components/ai-content" */).then(c => wrapFunctional(c.default || c)),
  AiTable: () => import('../../components/ai/aiTable.vue' /* webpackChunkName: "components/ai-table" */).then(c => wrapFunctional(c.default || c)),
  AnalysisAgencyMatters: () => import('../../components/analysis/agencyMatters.vue' /* webpackChunkName: "components/analysis-agency-matters" */).then(c => wrapFunctional(c.default || c)),
  AnalysisAgencySituation: () => import('../../components/analysis/agencySituation.vue' /* webpackChunkName: "components/analysis-agency-situation" */).then(c => wrapFunctional(c.default || c)),
  AnalysisBrandProtection: () => import('../../components/analysis/brandProtection.vue' /* webpackChunkName: "components/analysis-brand-protection" */).then(c => wrapFunctional(c.default || c)),
  AnalysisObjAndLaw: () => import('../../components/analysis/objAndLaw.vue' /* webpackChunkName: "components/analysis-obj-and-law" */).then(c => wrapFunctional(c.default || c)),
  AnalysisServiceTop: () => import('../../components/analysis/serviceTop.vue' /* webpackChunkName: "components/analysis-service-top" */).then(c => wrapFunctional(c.default || c)),
  AnalysisTop20: () => import('../../components/analysis/top20.vue' /* webpackChunkName: "components/analysis-top20" */).then(c => wrapFunctional(c.default || c)),
  BusinessCustomReports: () => import('../../components/business/businessCustomReports.vue' /* webpackChunkName: "components/business-custom-reports" */).then(c => wrapFunctional(c.default || c)),
  BusinessSearch: () => import('../../components/business/businessSearch.vue' /* webpackChunkName: "components/business-search" */).then(c => wrapFunctional(c.default || c)),
  BusinessCommonSearchItem: () => import('../../components/business/commonSearchItem.vue' /* webpackChunkName: "components/business-common-search-item" */).then(c => wrapFunctional(c.default || c)),
  BusinessList: () => import('../../components/business/list.vue' /* webpackChunkName: "components/business-list" */).then(c => wrapFunctional(c.default || c)),
  BusinessSearchCondition: () => import('../../components/business/searchCondition.vue' /* webpackChunkName: "components/business-search-condition" */).then(c => wrapFunctional(c.default || c)),
  BusinessSearchContent: () => import('../../components/business/searchContent.vue' /* webpackChunkName: "components/business-search-content" */).then(c => wrapFunctional(c.default || c)),
  ClassifyMenu: () => import('../../components/classify/classifyMenu.vue' /* webpackChunkName: "components/classify-menu" */).then(c => wrapFunctional(c.default || c)),
  ClassifyRetrieval: () => import('../../components/classify/classifyRetrieval.vue' /* webpackChunkName: "components/classify-retrieval" */).then(c => wrapFunctional(c.default || c)),
  ClassifySearch: () => import('../../components/classify/classifySearch.vue' /* webpackChunkName: "components/classify-search" */).then(c => wrapFunctional(c.default || c)),
  ClassifySearchList: () => import('../../components/classify/classifySearchList.vue' /* webpackChunkName: "components/classify-search-list" */).then(c => wrapFunctional(c.default || c)),
  ClassifyData: () => import('../../components/classify/data.js' /* webpackChunkName: "components/classify-data" */).then(c => wrapFunctional(c.default || c)),
  CommonSearchArea: () => import('../../components/common/commonSearchArea.vue' /* webpackChunkName: "components/common-search-area" */).then(c => wrapFunctional(c.default || c)),
  CommonSearchCondition: () => import('../../components/common/commonSearchCondition.vue' /* webpackChunkName: "components/common-search-condition" */).then(c => wrapFunctional(c.default || c)),
  CommonSearchContent: () => import('../../components/common/commonSearchContent.vue' /* webpackChunkName: "components/common-search-content" */).then(c => wrapFunctional(c.default || c)),
  CommonSearchItem: () => import('../../components/common/commonSearchItem.vue' /* webpackChunkName: "components/common-search-item" */).then(c => wrapFunctional(c.default || c)),
  CommonSearchTab: () => import('../../components/common/commonSearchTab.vue' /* webpackChunkName: "components/common-search-tab" */).then(c => wrapFunctional(c.default || c)),
  DocumentSearchCriteria: () => import('../../components/document/SearchCriteria.vue' /* webpackChunkName: "components/document-search-criteria" */).then(c => wrapFunctional(c.default || c)),
  DocumentDetails: () => import('../../components/document/details.vue' /* webpackChunkName: "components/document-details" */).then(c => wrapFunctional(c.default || c)),
  DocumentExplain: () => import('../../components/document/explain.vue' /* webpackChunkName: "components/document-explain" */).then(c => wrapFunctional(c.default || c)),
  DocumentSearch: () => import('../../components/document/search.vue' /* webpackChunkName: "components/document-search" */).then(c => wrapFunctional(c.default || c)),
  DocumentTable: () => import('../../components/document/table.vue' /* webpackChunkName: "components/document-table" */).then(c => wrapFunctional(c.default || c)),
  DocumentTableLaw: () => import('../../components/document/tableLaw.vue' /* webpackChunkName: "components/document-table-law" */).then(c => wrapFunctional(c.default || c)),
  DocumentTableTop: () => import('../../components/document/tableTop.vue' /* webpackChunkName: "components/document-table-top" */).then(c => wrapFunctional(c.default || c)),
  EchartsAgentCount: () => import('../../components/echarts/agentCount.vue' /* webpackChunkName: "components/echarts-agent-count" */).then(c => wrapFunctional(c.default || c)),
  EchartsCake: () => import('../../components/echarts/cake.vue' /* webpackChunkName: "components/echarts-cake" */).then(c => wrapFunctional(c.default || c)),
  EchartsClassCount: () => import('../../components/echarts/classCount.vue' /* webpackChunkName: "components/echarts-class-count" */).then(c => wrapFunctional(c.default || c)),
  EchartsColumnar: () => import('../../components/echarts/columnar.vue' /* webpackChunkName: "components/echarts-columnar" */).then(c => wrapFunctional(c.default || c)),
  EchartsLawbookCake: () => import('../../components/echarts/lawbookCake.vue' /* webpackChunkName: "components/echarts-lawbook-cake" */).then(c => wrapFunctional(c.default || c)),
  EchartsLawbookProportion: () => import('../../components/echarts/lawbookProportion.vue' /* webpackChunkName: "components/echarts-lawbook-proportion" */).then(c => wrapFunctional(c.default || c)),
  EchartsLawbookTime: () => import('../../components/echarts/lawbookTime.vue' /* webpackChunkName: "components/echarts-lawbook-time" */).then(c => wrapFunctional(c.default || c)),
  EchartsMapchina: () => import('../../components/echarts/mapchina.vue' /* webpackChunkName: "components/echarts-mapchina" */).then(c => wrapFunctional(c.default || c)),
  EchartsOAndLCount: () => import('../../components/echarts/oAndLCount.vue' /* webpackChunkName: "components/echarts-o-and-l-count" */).then(c => wrapFunctional(c.default || c)),
  EchartsStateStatistics: () => import('../../components/echarts/stateStatistics.vue' /* webpackChunkName: "components/echarts-state-statistics" */).then(c => wrapFunctional(c.default || c)),
  EchartsTimeTrend: () => import('../../components/echarts/timeTrend.vue' /* webpackChunkName: "components/echarts-time-trend" */).then(c => wrapFunctional(c.default || c)),
  EchartsTimeTrendBar: () => import('../../components/echarts/timeTrendBar.vue' /* webpackChunkName: "components/echarts-time-trend-bar" */).then(c => wrapFunctional(c.default || c)),
  ImgSearchClassGroup: () => import('../../components/imgSearch/classGroup.vue' /* webpackChunkName: "components/img-search-class-group" */).then(c => wrapFunctional(c.default || c)),
  ImgCrop: () => import('../../components/imgSearch/imgCrop.vue' /* webpackChunkName: "components/img-crop" */).then(c => wrapFunctional(c.default || c)),
  ImgSearchFiles: () => import('../../components/imgSearch/imgSearchFiles.vue' /* webpackChunkName: "components/img-search-files" */).then(c => wrapFunctional(c.default || c)),
  ImgSearchLayer: () => import('../../components/imgSearch/imgSearchLayer.vue' /* webpackChunkName: "components/img-search-layer" */).then(c => wrapFunctional(c.default || c)),
  InternationalCommonlist: () => import('../../components/international/commonlist.vue' /* webpackChunkName: "components/international-commonlist" */).then(c => wrapFunctional(c.default || c)),
  InternationalList: () => import('../../components/international/list.vue' /* webpackChunkName: "components/international-list" */).then(c => wrapFunctional(c.default || c)),
  LawbookSearch: () => import('../../components/lawbook/search.vue' /* webpackChunkName: "components/lawbook-search" */).then(c => wrapFunctional(c.default || c)),
  LawbookTable: () => import('../../components/lawbook/table.vue' /* webpackChunkName: "components/lawbook-table" */).then(c => wrapFunctional(c.default || c)),
  LayerAgency: () => import('../../components/layer/agency.vue' /* webpackChunkName: "components/layer-agency" */).then(c => wrapFunctional(c.default || c)),
  LayerApproximateCheck: () => import('../../components/layer/approximateCheck.vue' /* webpackChunkName: "components/layer-approximate-check" */).then(c => wrapFunctional(c.default || c)),
  LayerBrandRisk: () => import('../../components/layer/brandRisk.vue' /* webpackChunkName: "components/layer-brand-risk" */).then(c => wrapFunctional(c.default || c)),
  LayerCommonNotive: () => import('../../components/layer/commonNotive.vue' /* webpackChunkName: "components/layer-common-notive" */).then(c => wrapFunctional(c.default || c)),
  LayerDocumentDetails: () => import('../../components/layer/documentDetails.vue' /* webpackChunkName: "components/layer-document-details" */).then(c => wrapFunctional(c.default || c)),
  LayerGetUserType: () => import('../../components/layer/getUserType.vue' /* webpackChunkName: "components/layer-get-user-type" */).then(c => wrapFunctional(c.default || c)),
  LayerMonitor: () => import('../../components/layer/layerMonitor.vue' /* webpackChunkName: "components/layer-monitor" */).then(c => wrapFunctional(c.default || c)),
  LayerReport: () => import('../../components/layer/layerReport.vue' /* webpackChunkName: "components/layer-report" */).then(c => wrapFunctional(c.default || c)),
  LayerSelInter: () => import('../../components/layer/layerSelInter.vue' /* webpackChunkName: "components/layer-sel-inter" */).then(c => wrapFunctional(c.default || c)),
  MonitorList: () => import('../../components/monitor/list.vue' /* webpackChunkName: "components/monitor-list" */).then(c => wrapFunctional(c.default || c)),
  Notice: () => import('../../components/notice/index.js' /* webpackChunkName: "components/notice" */).then(c => wrapFunctional(c.default || c)),
  NoticeNumber: () => import('../../components/notice/noticeNumber.vue' /* webpackChunkName: "components/notice-number" */).then(c => wrapFunctional(c.default || c)),
  SearchAlike: () => import('../../components/search/SearchAlike.vue' /* webpackChunkName: "components/search-alike" */).then(c => wrapFunctional(c.default || c)),
  SearchClass: () => import('../../components/search/SearchClass.vue' /* webpackChunkName: "components/search-class" */).then(c => wrapFunctional(c.default || c)),
  SearchCountMenu: () => import('../../components/search/SearchCountMenu.vue' /* webpackChunkName: "components/search-count-menu" */).then(c => wrapFunctional(c.default || c)),
  SearchCriteria: () => import('../../components/search/SearchCriteria.vue' /* webpackChunkName: "components/search-criteria" */).then(c => wrapFunctional(c.default || c)),
  SearchFilterTip: () => import('../../components/search/SearchFilterTip.vue' /* webpackChunkName: "components/search-filter-tip" */).then(c => wrapFunctional(c.default || c)),
  SearchHeadDate: () => import('../../components/search/SearchHeadDate.vue' /* webpackChunkName: "components/search-head-date" */).then(c => wrapFunctional(c.default || c)),
  SearchHeadTh: () => import('../../components/search/SearchHeadTh.vue' /* webpackChunkName: "components/search-head-th" */).then(c => wrapFunctional(c.default || c)),
  SearchHistory: () => import('../../components/search/SearchHistory.vue' /* webpackChunkName: "components/search-history" */).then(c => wrapFunctional(c.default || c)),
  SearchMenu: () => import('../../components/search/SearchMenu.vue' /* webpackChunkName: "components/search-menu" */).then(c => wrapFunctional(c.default || c)),
  SearchPicList: () => import('../../components/search/SearchPicList.vue' /* webpackChunkName: "components/search-pic-list" */).then(c => wrapFunctional(c.default || c)),
  SearchQuery: () => import('../../components/search/SearchQuery.vue' /* webpackChunkName: "components/search-query" */).then(c => wrapFunctional(c.default || c)),
  SearchStatistical: () => import('../../components/search/SearchStatistical.vue' /* webpackChunkName: "components/search-statistical" */).then(c => wrapFunctional(c.default || c)),
  SearchTable: () => import('../../components/search/SearchTable.vue' /* webpackChunkName: "components/search-table" */).then(c => wrapFunctional(c.default || c)),
  SearchType: () => import('../../components/search/SearchType.vue' /* webpackChunkName: "components/search-type" */).then(c => wrapFunctional(c.default || c)),
  SearchGlGroupTab: () => import('../../components/search/glGroupTab.vue' /* webpackChunkName: "components/search-gl-group-tab" */).then(c => wrapFunctional(c.default || c)),
  SpecialList: () => import('../../components/special/list.vue' /* webpackChunkName: "components/special-list" */).then(c => wrapFunctional(c.default || c)),
  SpecialSearch: () => import('../../components/special/search.vue' /* webpackChunkName: "components/special-search" */).then(c => wrapFunctional(c.default || c)),
  SpecialSearchAreaMain: () => import('../../components/special/searchAreaMain.vue' /* webpackChunkName: "components/special-search-area-main" */).then(c => wrapFunctional(c.default || c)),
  SpecialPage: () => import('../../components/special/specialPage.vue' /* webpackChunkName: "components/special-page" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchMain: () => import('../../components/trademarkSearch/searchMain.vue' /* webpackChunkName: "components/trademark-search-main" */).then(c => wrapFunctional(c.default || c)),
  BusinessTypeAreaSelect: () => import('../../components/business/type/areaSelect.vue' /* webpackChunkName: "components/business-type-area-select" */).then(c => wrapFunctional(c.default || c)),
  BusinessTypeBusType: () => import('../../components/business/type/busType.vue' /* webpackChunkName: "components/business-type-bus-type" */).then(c => wrapFunctional(c.default || c)),
  InternationalDetailsEUIPO: () => import('../../components/international/details/EUIPO.vue' /* webpackChunkName: "components/international-details-e-u-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  InternationalDetailsUS: () => import('../../components/international/details/US.vue' /* webpackChunkName: "components/international-details-u-s" */).then(c => wrapFunctional(c.default || c)),
  LayerJsMonitorForm: () => import('../../components/layer/js/monitorForm.js' /* webpackChunkName: "components/layer-js-monitor-form" */).then(c => wrapFunctional(c.default || c)),
  SpecialJsData: () => import('../../components/special/js/data.js' /* webpackChunkName: "components/special-js-data" */).then(c => wrapFunctional(c.default || c)),
  SpecialTypeAddress: () => import('../../components/special/type/specialTypeAddress.vue' /* webpackChunkName: "components/special-type-address" */).then(c => wrapFunctional(c.default || c)),
  SpecialTypeCity: () => import('../../components/special/type/specialTypeCity.vue' /* webpackChunkName: "components/special-type-city" */).then(c => wrapFunctional(c.default || c)),
  SpecialTypeCountry: () => import('../../components/special/type/specialTypeCountry.vue' /* webpackChunkName: "components/special-type-country" */).then(c => wrapFunctional(c.default || c)),
  SpecialTypeCounty: () => import('../../components/special/type/specialTypeCounty.vue' /* webpackChunkName: "components/special-type-county" */).then(c => wrapFunctional(c.default || c)),
  SpecialTypeProvince: () => import('../../components/special/type/specialTypeProvince.vue' /* webpackChunkName: "components/special-type-province" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchCommonHighClass: () => import('../../components/trademarkSearch/common/highClass.vue' /* webpackChunkName: "components/trademark-search-common-high-class" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchCommonHighOther: () => import('../../components/trademarkSearch/common/highOther.vue' /* webpackChunkName: "components/trademark-search-common-high-other" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchCommonHighType: () => import('../../components/trademarkSearch/common/highType.vue' /* webpackChunkName: "components/trademark-search-common-high-type" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeAgency: () => import('../../components/trademarkSearch/type/agency.vue' /* webpackChunkName: "components/trademark-search-type-agency" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeApproximate: () => import('../../components/trademarkSearch/type/approximate.vue' /* webpackChunkName: "components/trademark-search-type-approximate" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeCfBlind: () => import('../../components/trademarkSearch/type/cfBlind.vue' /* webpackChunkName: "components/trademark-search-type-cf-blind" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeCode: () => import('../../components/trademarkSearch/type/code.vue' /* webpackChunkName: "components/trademark-search-type-code" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeComposite: () => import('../../components/trademarkSearch/type/composite.vue' /* webpackChunkName: "components/trademark-search-type-composite" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeIdentical: () => import('../../components/trademarkSearch/type/identical.vue' /* webpackChunkName: "components/trademark-search-type-identical" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeName: () => import('../../components/trademarkSearch/type/name.vue' /* webpackChunkName: "components/trademark-search-type-name" */).then(c => wrapFunctional(c.default || c)),
  TrademarkSearchTypeProposer: () => import('../../components/trademarkSearch/type/proposer.vue' /* webpackChunkName: "components/trademark-search-type-proposer" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
